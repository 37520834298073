import React from 'react';
import { Text, Link, View } from '@react-pdf/renderer';
import { useLanguageContext } from 'contexts/languageContext';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { FRENCH } from 'utils/language-options';
import { yearFromDate } from 'utils/date-time-formatter';
import { STATUS_U3, STATUS_VK } from 'utils/organization-certificate-status';
import coverLetterStyles from 'components/pdf/styles/anerkennung/cover-letter';
import { Break, ListItem } from '../../shared/shared-components';

const BioSuisseStatusChangeContent = ({
  status,
  organization,
  contolBodyOrganization,
  language,
}) => {
  const { t } = useLanguageContext();
  const orgCustomerSince = yearFromDate(organization.customerSince);
  const ITEM_3_URL_TEXT = 'www.bio-diversitaet.ch';

  const itemFiveLink = {
    de: 'www.bioaktuell.ch/grundlagen/bioregelwerk',
    fr: 'www.bioactualites.ch/principes/reglementationbio',
    it: 'www.bioattualita.ch/basi/normativebio',
  };

  return (
    <>
      <Text style={coverLetterStyles.description}>
        {status === STATUS_U3 ? (
          t(`pdf.anerkennung.cover-letter.${status}.description.section_1`, {
            contolBodyOrganization: contolBodyOrganization?.name,
          })
        ) : (
          <Trans
            i18nKey={`pdf.anerkennung.cover-letter.${status}.description.section_1`}
            values={{
              orgCustomerSince,
            }}
            components={{ sup: <Text style={coverLetterStyles.superscript} /> }}
          />
        )}

        {[STATUS_U3, STATUS_VK].includes(status) && (
          <Link src="https://www.easy-cert.com/htm/zertifikate.htm">www.easy-cert.ch</Link>
        )}
        {t(`pdf.anerkennung.cover-letter.${status}.description.section_2`)}
        <Link src="http://www.bio-suisse.ch/produzenten" style={coverLetterStyles.bottomMargin}>
          www.bio-suisse.ch/produzenten
        </Link>
        <Break count={language === FRENCH ? 4 : 2} />
      </Text>

      <View style={coverLetterStyles.table}>
        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_1`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_1`)}
            </Text>
          </View>
        </View>

        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_2`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_2`)}
            </Text>
          </View>
        </View>

        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_3`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_3`)}
              <Link
                src={
                  language === FRENCH
                    ? `https://www.bio-diversitaet.ch/${language}`
                    : 'https://www.bio-diversitaet.ch'
                }
              >
                {language === FRENCH ? `${ITEM_3_URL_TEXT}/${language}` : ITEM_3_URL_TEXT}
              </Link>
            </Text>
          </View>
        </View>

        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_4`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              <Link src="http://www.biomondo.ch/">www.biomondo.ch</Link>
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_4`)}
            </Text>
          </View>
        </View>

        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_5`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_5`)}
              <Link src={`https://${itemFiveLink[language]}`}>{itemFiveLink[language]}</Link>
            </Text>
          </View>
        </View>

        <View style={coverLetterStyles.tableRow}>
          <View style={coverLetterStyles.tableCol1}>
            <ListItem bulletPoint={true}>
              {t(`pdf.anerkennung.cover-letter.table.col_1.item_6`)}
            </ListItem>
          </View>
          <View style={coverLetterStyles.tableCol2}>
            <Text style={coverLetterStyles.cell}>
              <Link src="http://www.bioaktuell.ch/">www.bioaktuell.ch</Link>{' '}
              {t(`pdf.anerkennung.cover-letter.table.col_2.item_6`)}
            </Text>
          </View>
        </View>
      </View>

      {status !== STATUS_VK && (
        <Text>
          {t(`pdf.anerkennung.cover-letter.${status}.order_question`)}
          <Link src={'http://www.bio-suisse.ch/vignetten'}>www.bio-suisse.ch/vignetten</Link>
          <Break count={2} />
        </Text>
      )}

      <Text>
        {t(`pdf.anerkennung.cover-letter.${status}.question`)}
        <Link src={`mailto: administration@bio-suisse.ch`}>administration@bio-suisse.ch</Link>
        <Break />
        <Text>
          <Break />
          {t(`pdf.anerkennung.cover-letter.${status}.best_wish`)}
        </Text>
      </Text>
    </>
  );
};

BioSuisseStatusChangeContent.propTypes = {
  organization: PropTypes.shape().isRequired,
  status: PropTypes.string.isRequired,
  contolBodyOrganization: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
};

export default BioSuisseStatusChangeContent;
