import React from 'react';
import PropTypes from 'prop-types';
import { BlobProvider } from '@react-pdf/renderer';
import Spinner from 'components/base-components/Spinner/Spinner';
import { STATUS_U3, STATUS_UK, STATUS_VK } from 'utils/organization-certificate-status';
import i18next from 'i18next';
import LanguageContext from 'contexts/languageContext';
import Link from 'components/base-components/Link';
import CoverLetterPDF from 'components/pdf/anerkennung/cover-letter/cover-letter.document.jsx';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery } from 'api/users';
import { useGetOrganizationQuery } from 'api/organizations';
import { addressChange } from 'utils/recognition_types';

const RecognitionPDFComponent = ({ organization, certificateGenDate, recognitionOf, certificate }) => {
  const t = i18next.getFixedT(i18next.language === 'en' ? 'de' : i18next.language);
  const { t: translate } = useTranslation();
  const { data: user = {} } = useGetUserQuery(
    { id: organization?.mainContactId },
    { skip: !organization?.mainContactId },
  );
  const { data: contolBodyOrganization } = useGetOrganizationQuery(
    organization?.controlBodyOrganizationId,
    {
      skip: !organization?.controlBodyOrganizationId,
    },
  );

  let coverLetterStatus;

  switch (certificate?.metadata?.newCertificationStatus) {
    case 'u1':
    case 'u2':
      coverLetterStatus = STATUS_UK;
      break;
    case 'u3':
      coverLetterStatus = STATUS_U3;
      break;
    case 'vk':
      coverLetterStatus = STATUS_VK;
      break;
    default:
      break;
  }

  if (recognitionOf === addressChange) {
    coverLetterStatus = recognitionOf;
  }

  const certificationStatus = ['u1', 'u2', 'u3'].includes(
    certificate?.metadata?.newCertificationStatus,
  )
    ? STATUS_UK
    : STATUS_VK;
  const COVER_LETTER_PDF_FILENAME = `Begleitbrief ${certificationStatus.toUpperCase()}-Anerkennung.pdf`;

  return (
    <>
      <div className="task__popover-content">
        <BlobProvider
          document={
            <LanguageContext.Provider value={{ t }}>
              <CoverLetterPDF
                organization={organization}
                generationDate={certificateGenDate}
                status={coverLetterStatus}
                user={user}
                contolBodyOrganization={contolBodyOrganization}
                language={i18next.language}
              />
            </LanguageContext.Provider>
          }
        >
          {({ blob, url, loading }) => {
            return (
              <>
                {loading ? (
                  <Spinner bgColor="none" color="success" size="tiny" />
                ) : (
                  <Link
                    download={COVER_LETTER_PDF_FILENAME}
                    type="plain"
                    size="small"
                    href={url}
                    modifier="default"
                  >
                    <div>
                      {translate('recognition.details.table.popovers.download_cover_letter')}
                    </div>
                  </Link>
                )}
              </>
            );
          }}
        </BlobProvider>
      </div>
    </>
  );
};

RecognitionPDFComponent.propTypes = {
  organization: PropTypes.shape().isRequired,
  certificateGenDate: PropTypes.string.isRequired,
  certificate: PropTypes.shape().isRequired,
  recognitionOf: PropTypes.string.isRequired,
};

export default RecognitionPDFComponent;
