import { Document, Image, Page, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../recognition/footer.view';
import tvdStyles from '../styles/tvd-styles';
import FirstPage from './conent/first-page.view';
import PPAInfo from './conent/ppa-info.view';
import SecondPage from './conent/second-page.view';
import StickerPage from './conent/sticker-page.view';

const TVDStickerPDFDocument = ({ organization, barcodeImageUrl, tvdNumber, labels, address }) => (
  <Document>
    <Page size="A4" style={[tvdStyles.page, { paddingBottom: 20 }]}>
      <View>
        <Image style={tvdStyles.headerLogo} src={BioLogo} />
      </View>
      <View style={tvdStyles.body}>
        <PPAInfo />
        <FirstPage organization={organization} address={address} />
        <SecondPage />
      </View>
      <Footer customStyle={tvdStyles.footer} />
    </Page>
    <Page size="A4" style={tvdStyles.page}>
      <StickerPage
        organization={organization}
        barcodeImageUrl={barcodeImageUrl}
        tvdNumber={tvdNumber}
        address={address}
        labels={labels}
      />
    </Page>
  </Document>
);

TVDStickerPDFDocument.propTypes = {
  organization: PropTypes.shape().isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  tvdNumber: PropTypes.string.isRequired,
  address: PropTypes.shape().isRequired,
  labels: PropTypes.array.isRequired,
};

export default TVDStickerPDFDocument;
