import { Image, Link, Text, View } from '@react-pdf/renderer';
import HeaderImage from 'assets/merkblatt.png';
import TvdSample from 'assets/tvd_sample.png';
import { ListItem } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import React from 'react';
import { COLOR_BLACK } from 'components/pdf/styles/variables';

const listItemProps = {
  listStyle: 'largeSquare',
  style: tvdStyles.listPadding,
  prefixStyle: { paddingTop: 2 },
  textStyle: { letterSpacing: -0.1 },
};

const SecondPage = () => (
  <View>
    <Image style={tvdStyles.statusLogo} src={HeaderImage} />
    <View style={tvdStyles.secondPageContentBody}>
      <Text style={tvdStyles.headerTitle}>{t('pdf.tvd.second.title')}</Text>
      <Text>{t('pdf.tvd.second.date')}</Text>
      <Text style={tvdStyles.marginTop20}>{t('pdf.tvd.second.line1')}</Text>
      <View style={{ ...tvdStyles.flex, ...tvdStyles.marginTop10 }}>
        <Image src={TvdSample} />
        <View style={tvdStyles.stickerText}>
          <Text>{t('pdf.tvd.second.sticker_text1')}</Text>
          <Text style={tvdStyles.marginTop20}>{t('pdf.tvd.second.sticker_text2')}</Text>
        </View>
      </View>
      <Text style={{ ...tvdStyles.marginTop30, ...tvdStyles.textBold }}>
        {t('pdf.tvd.second.line2_title')}
      </Text>
      <Text style={{ ...tvdStyles.marginTop10, ...tvdStyles.textBold }}>
        {t('pdf.tvd.second.line3_title')}
      </Text>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list1')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list2')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list3')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list4')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list5')}</ListItem>
      <Text style={{ ...tvdStyles.marginTop10 }}>
        <Trans
          i18nKey="pdf.tvd.second.line4"
          components={{ link: <Link src="https://www.bio-suisse.ch/vignetten" /> }}
        />
      </Text>
      <Text style={tvdStyles.marginTop20}>
        {t('pdf.tvd.second.line_5.part1')}
        <Link src="mailto:vignetten@bio-suisse.ch" style={{ color: COLOR_BLACK }}>
          vignetten@bio-suisse.ch
        </Link>
        {t('pdf.tvd.second.line_5.part2')}
      </Text>
    </View>
  </View>
);

export default SecondPage;
